import "../../css/Partials.css";
import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      <p>&copy; Yuki / 2022</p>
    </div>
  );
}
